













































































import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';

export default Vue.extend({
  data() {
    return {
      page: 0,
    };
  },
  async mounted() {
    await this.fetchData();
    this.loadMore();
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      comments: 'presenter/getSessionComments',
      loading: 'presenter/getLoadingState',
      userTheme: 'auth/getUserTheme',
    }),
  },
  methods: {
    ...mapMutations({
      resetComments: 'presenter/RESET_POLL_COMMENTS',
    }),
    fetchData(): void {
      const presenterSessionId = this.$route.params.presenterSessionId;
      const pollId = this.$route.params.pollId;
      this.$store.dispatch('presenter/fetchSessionComments', {
        shareToken: presenterSessionId,
        pollId,
        page: this.page,
      });
    },
    async loadMore() {
      window.onscroll = () => {
        const bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.page += 1;
          this.fetchData();
        }
      };
    },
  },
  beforeDestroy(): void {
    this.resetComments();
  },
});
